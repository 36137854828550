import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import organizeBlob from '../../images/home/features/organize.svg';
import aboutBlob from '../../images/jobs/about_blob.svg';
import accoladeCapterra from '../../images/jobs/accolades/capterra.png';
import accoladeG2 from '../../images/jobs/accolades/g2.svg';
import accoladeProductHunt from '../../images/jobs/accolades/product_hunt.png';
import benefitsImage from '../../images/jobs/benefits.jpg';
import benefitsBlob from '../../images/jobs/benefits_blob.svg';
import headshotsBlob from '../../images/jobs/headshots_blob.svg';
import iconChart from '../../images/jobs/icons/chart-line-up.svg';
import iconDollar from '../../images/jobs/icons/currency-circle-dollar.svg';
import iconDesktop from '../../images/jobs/icons/desktop.svg';
import iconHeartbeat from '../../images/jobs/icons/heartbeat.svg';
import positionsIcon from '../../images/jobs/icons/positions.svg';
import valuesIcon from '../../images/jobs/icons/values.svg';
import teamPhoto from '../../images/jobs/team.jpg';
import Button from '../ui/Button';
import PageSection from '../ui/PageSection';
import BenefitsListItem from './BenefitsListItem';
import styles from './JobsContent.module.scss';
import Positions from './Positions';

const HEADSHOT_COUNT = 6;

const getHeadshotUrls = (context: ReturnType<typeof require.context>) =>
  context.keys().map((path: string) => context(path).default);

const elevatedHeadshots = getHeadshotUrls(
  require.context('../../images/jobs/headshots/elevate/', false, /\.jpg$/),
);

const normalHeadshots = getHeadshotUrls(
  require.context('../../images/jobs/headshots/', false, /\.jpg$/),
);

const getHeadshots = (): string[] => {
  const [elevated, ...remaining] = _.shuffle(elevatedHeadshots);
  const picked = _.shuffle([
    elevated,
    ..._.sampleSize([...normalHeadshots, ...remaining], HEADSHOT_COUNT - 1),
  ]);

  return picked;
};

const JobsContent: React.FunctionComponent = () => (
  <div className={styles.container}>
    <PageSection>
      <div className={styles.headerSection}>
        <div className={styles.headerSectionImages}>
          <img
            src={headshotsBlob}
            className={styles.headerSectionShapes}
            alt=""
            role="presentation"
          />
          {typeof window !== 'undefined' &&
            getHeadshots().map((image, index) => (
              <img
                key={image.split('/').pop()}
                src={image}
                className={styles[`headshot${index + 1}`]}
                alt=""
                role="presentation"
              />
            ))}
        </div>
        <div className={classNames(styles.introText, styles.headerCopy)}>
          <h2 className={styles.introHeading}>Careers at Slab.</h2>
          <p>
            We are on a mission to make the workplace a source of learning and
            purpose through knowledge-sharing.
          </p>
          <p>
            As a fully remote company, we welcome talented people from all
            backgrounds all across the world.
          </p>
          <p>Come join us!</p>
          <div className={styles.headerCTAContainer}>
            <Button to="#positions" primary short block>
              See Open Positions
            </Button>
          </div>
        </div>
      </div>
    </PageSection>

    <PageSection className={styles.accoladesSection}>
      <h3 className={styles.accoladesHeading}>Accolades</h3>
      <div className={styles.accoladesImages}>
        <img
          src={accoladeProductHunt}
          alt="Product Hunt: No. 1 product of the week at launch"
        />
        <img src={accoladeG2} alt="G2: Top 100 Highest Satisfaction Products" />
        <img src={accoladeCapterra} alt="Capterra: 4.9 out of 5 stars" />
      </div>
    </PageSection>

    <PageSection>
      <div
        className={classNames(styles.imageAndTextContainer, styles.reversed)}
      >
        <div className={styles.textContainer}>
          <h3>Work on a product that users love.</h3>
          <p>
            Slab helps teams all over the world work more productively and
            transparently. We’ve aligned our interests with our customers’ with
            a straightforward business model that prospers when they do.
          </p>
        </div>
        <div className={styles.imageContainer}>
          <img src={organizeBlob} alt="Slab topic" />
        </div>
      </div>
      <div
        className={classNames(
          styles.imageAndTextContainer,
          styles.benefitsSection,
        )}
      >
        <div
          className={classNames(
            styles.imageContainer,
            styles.benefitsSectionImage,
          )}
        >
          <img
            src={benefitsBlob}
            className={styles.benefitsSectionShapes}
            alt=""
            role="presentation"
          />
          <img
            src={benefitsImage}
            alt="Parent at work"
            className={styles.benefitsSectionPhoto}
          />
        </div>
        <div className={styles.textContainer}>
          <h3 className={styles.benefitsHeader}>
            Benefits to support your impact.
          </h3>

          <BenefitsListItem icon={iconHeartbeat} iconAlt="Heartbeat">
            Full health, dental &amp; vision insurance (USA) or equivalent
            stipend (Global)
          </BenefitsListItem>
          <BenefitsListItem icon={iconDollar} iconAlt="Currency">
            Wellness &amp; remote work stipends
          </BenefitsListItem>
          <BenefitsListItem icon={iconDesktop} iconAlt="Desktop">
            $5k desk setup of your choice
          </BenefitsListItem>
          <BenefitsListItem icon={iconChart} iconAlt="Chart increasing">
            7-year option exercise window
          </BenefitsListItem>
        </div>
      </div>
    </PageSection>

    <PageSection id="values" className={styles.valuesSection}>
      <div className={styles.iconHeader}>
        <img src={valuesIcon} alt="" role="presentation" />
        <h2>Our values</h2>
      </div>
      <p className={styles.valuesIntro}>
        Our values — both their upsides and downsides — make our team uniquely
        who we are.
      </p>
      <div className={styles.values}>
        <div className={classNames(styles.value, styles.valueOne)}>
          <h4>Stay lean</h4>
          <p>
            We strive for the greatest possible impact with the fewest number of
            employees. We empower our teammates with the most leveraged tools
            and efficient processes.
          </p>
          <ul>
            <li>You keep us nimble by minimizing complexity</li>
            <li>
              You utilize existing solutions when possible to avoid unnecessary
              reinvention
            </li>
          </ul>
        </div>
        <div className={classNames(styles.value, styles.valueFour)}>
          <h4>Say no</h4>
          <p>
            We aim to deliver exceptionally high value in a small set of focus
            areas. We willingly abstain from good ideas to give only the most
            promising paths the attention they deserve.
          </p>
          <ul>
            <li>You clearly identify what you are and are not trying to do</li>
            <li>You think about removing features as much as adding them</li>
          </ul>
        </div>
        <div className={classNames(styles.value, styles.valueTwo)}>
          <h4>Default to open</h4>
          <p>
            We encourage and nurture open exchanges of knowledge and ideas —
            while acting with respect and regard for each other.
          </p>
          <ul>
            <li>You trust your teammates to do their best work</li>
            <li>
              You are transparent, direct, and honest in your communication
            </li>
            <li>You thrive on receiving and giving constructive feedback</li>
          </ul>
        </div>
        <div className={classNames(styles.value, styles.valueFive)}>
          <h4>The best prevails</h4>
          <p>
            Whether an idea or an individual, the best will rise to the top at
            Slab. Ideas we pursue can come from anywhere, and individuals gain
            responsibilities due to outperformance.
          </p>
          <ul>
            <li>
              You are more interested in getting to the truth than being right
            </li>
            <li>
              You recognize talent and potential in people of diverse
              backgrounds and cultures
            </li>
            <li>You view new challenges as growth opportunities</li>
          </ul>
        </div>
        <div className={classNames(styles.value, styles.valueThree)}>
          <h4>Think rigorously</h4>
          <p>
            We act and execute after careful thought and examination of known
            information, while acknowledging the risks we accept in its absence.
          </p>
          <ul>
            <li>
              You are not satisfied when something works — you want to
              understand why
            </li>
            <li>You strive to keep learning, even as the expert in the room</li>
            <li>
              You dig deep into challenges to uncover the underlying problems
            </li>
          </ul>
        </div>
        <div className={classNames(styles.value, styles.valueSix)}>
          <h4>Global optimization</h4>
          <p>
            We believe that our mission — to make the workplace a source of
            learning and purpose — is the ultimate priority, above any single
            project, team, or individual.
          </p>
          <ul>
            <li>You internalize the company&apos;s mission and goals</li>
            <li>
              You are willing to help outside your individual role when needed
            </li>
          </ul>
        </div>
      </div>
    </PageSection>

    <PageSection id="positions" className={styles.positionsSection}>
      <div className={classNames(styles.iconHeader, styles.positionsIcon)}>
        <img src={positionsIcon} alt="" role="presentation" />
        <h3>Open Positions</h3>
      </div>
      <Positions />
    </PageSection>

    <PageSection background="light" className={styles.aboutSection}>
      <div className={styles.imageAndTextContainer}>
        <div className={styles.textContainer}>
          <h2>About Slab</h2>
          <p>
            Slab was founded in 2016 with the belief that shared knowledge
            empowers teams to do their best work. In a remote world, that
            foundation is even more critical. Our product has become the source
            of truth for thousands of companies, including Asana, Fivetran, and
            Vox Media, supporting executives to engineers to operations to
            sales, and many more.
          </p>
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.aboutSectionImages}>
            <img
              src={aboutBlob}
              className={styles.aboutSectionShapes}
              alt=""
              role="presentation"
            />
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img
              src={teamPhoto}
              alt="Team hangout picture"
              className={styles.aboutSectionPhoto}
            />
          </div>
        </div>
      </div>
    </PageSection>

    {/* <PageSection>
      <div className={styles.referralSection}>
        <h3 className={styles.referralHeading}>
          Know someone who would be a great fit? Let us know!
        </h3>
        <p>
          We offer an external referral bonus of $2,000 per hire, plus an
          additional $2,000 for underrepresented hires.{' '}
          <a
            href="https://slabhq.typeform.com/to/rNWL2Xdx"
            target="_blank"
            rel="noreferrer"
          >
            Refer your friend
          </a>{' '}
          today.{' '}
        </p>
      </div>
    </PageSection> */}
  </div>
);

export default JobsContent;
