import React from 'react';
import JobsContent from '../components/jobs/JobsContent';
import MarketingLayout from '../components/layout/MarketingLayout';

const Jobs: React.FunctionComponent = () => (
  <MarketingLayout title="Careers at Slab" noFooterPadding>
    <JobsContent />
  </MarketingLayout>
);

export default Jobs;
