import React, { useLayoutEffect, useState } from 'react';
import Button from '../ui/Button';
import styles from './Positions.module.scss';

type Position = [string, string, string, string?];

const POSITIONS: Position[] = [];

const getRef = () =>
  (typeof window !== 'undefined' &&
    new URLSearchParams(window.location.search).get('ref')) ||
  '';

const Positions: React.FunctionComponent = () => {
  const [referral, setReferral] = useState('');

  // Make sure ref is updated in browser window instead of using the value from SSR
  useLayoutEffect(() => {
    const newReferral = getRef();
    if (newReferral !== referral) {
      setReferral(newReferral);
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!POSITIONS.length) {
    return (
      <div className={styles.empty}>
        All of our current roles are filled but please check back later!
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {POSITIONS.map(([name, location, url, description]) => (
        <div className={styles.positionItem} key={url}>
          <div className={styles.positionName}>
            <h4>{name}</h4>
            <div className={styles.location}>{location}</div>
          </div>
          <p className={styles.description}>{description}</p>
          <div className={styles.button}>
            <Button to={url} target="_blank" block small>
              Learn More
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Positions;
