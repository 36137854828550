import React from 'react';
import styles from './BenefitsListItem.module.scss';

interface BenefitsListItemProps {
  icon: string;
  iconAlt: string;
  children: React.ReactNode;
}

const BenefitsListItem: React.FunctionComponent<BenefitsListItemProps> = ({
  icon,
  iconAlt,
  children,
}) => (
  <div className={styles.container}>
    <img src={icon} alt={iconAlt} />
    <span>{children}</span>
  </div>
);

export default BenefitsListItem;
